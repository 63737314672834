/* eslint-disable */
export const messages = {
  system_admin_user_add_company: "Add Company",
  system_admin_user_companies: "Companies",
  system_admin_user_companies_info: "There must be at least one company.<br />On edit mode, company deletion is not possible yet.",
  system_admin_user_company_and_roles: "Company and Roles",
  system_admin_user_company_name: "Company Name",
  system_admin_user_company_user: "Company User",
  system_admin_user_company_users: "Company Users",
  system_admin_user_create_company_user: "Create Company User",
  system_admin_user_create_system_user: "Create System User",
  system_admin_user_created: "Created",
  system_admin_user_deactivated: "Deactivated",
  system_admin_user_completed: "Completed",
  system_admin_user_expired: "Expired",
  system_admin_user_edit_company_user: "Edit Company User",
  system_admin_user_edit_system_user: "Edit System User",
  system_admin_user_english: "English",
  system_admin_user_german: "German",
  system_admin_user_profile_state: "Profile State",
  system_admin_user_roles: "Roles",
  system_admin_user_company_admin: "Administrator",
  system_admin_user_basic_access: "Basic",
  system_admin_user_ccf_user: "GLOBAL FOOTPRINT User",
  system_admin_user_esg_user: "GLOBAL COMPLIANCE User",
  system_admin_user_system_admin: "System Admin",
  system_admin_user_system_admin_user: "System Admin User",
  system_admin_user_system_roles: "System Roles",
  system_admin_user_system_user: "System User",
  system_admin_user_user_data: "User Data",
  system_admin_user_registration_link_description: "The user can complete the user profile on the following links:",
  system_admin_user_registration_link_valid_until: "Valid Until",
  system_admin_user_registration_links: "Registration Links",
  system_admin_user_update_user_warning: "Users need to re-login before changes on the roles visually take effect",
  system_admin_user_deactivate_warning: "User will not be able to login if you deactivate the user",
  system_admin_user_attention: "Attention",
  system_admin_user_email_dialog_title: "Send email",
  system_admin_user_email_dialog_alert: "Attention: An email will be sent. Please use this functionality carefully to avoid too many emails.",
};
