export const messages = {
  // Common navigation items
  common_navigation_integration: "Integration",
  common_navigation_show_pending_booking_pages: "Ausstehende Buchungsseiten anzeigen",

  // Customer navigation items
  customer_navigation_global_footprint: "GLOBAL FOOTPRINT",
  customer_navigation_global_footprint_ccf: "CCF-Tool",
  customer_navigation_global_footprint_pcf: "PCF-Tool",
  customer_navigation_global_footprint_sbt: "SBT-Tool",
  customer_navigation_sco2pes_bookings: "Buchungen",
  customer_navigation_sco2pes_base_data: "Stammdaten",
  customer_navigation_sco2pes_financial_models: "Finanzmodelle",
  customer_navigation_sco2pes_financial_bookings: "Finanzbuchungen",
  customer_navigation_sco2pes_pending_financial_bookings: "Ausstehende Finanzbuchungen",
  customer_navigation_sco2pes_unit_bookings: "Unit-Buchungen",
  customer_navigation_sco2pes_pending_unit_bookings: "Ausstehende Unit-Buchungen",
  customer_navigation_sco2pes_suppliers: "Lieferanten",
  customer_navigation_sco2pes_company_codes: "Buchungskreise",
  customer_navigation_sco2pes_commuter_surveys: "Pendlerumfragen",
  customer_navigation_sco2pes_co2_database: "CO<sub>2</sub> Datenbank",
  customer_navigation_sco2pes_reporting: "Reporting",
  customer_navigation_sco2pes_company: "Unternehmen",
  customer_navigation_sco2pes_company_group: "Unternehmensgruppe",
  customer_navigation_sco2pes_co2_type_tags: "CO<sub>2</sub>-Typ-Stichworte",
  customer_navigation_global_compliance: "GLOBAL COMPLIANCE",
  customer_navigation_esg_ratings: "Ratings",
  customer_navigation_esg_risk_analyses: "Risiko Analysen",
  customer_navigation_esg_administration: "Administration",
  customer_navigation_esg_administration_users: "Nutzer",
  customer_navigation_esg_administration_company: "Unternehmen",
  customer_navigation_esg_administration_licenses: "Lizenzen",
  customer_navigation_esg_support: "Support",
  customer_navigation_esg_profile: "Profil",
  customer_navigation_esg_account_selection: "Kontoauswahl",
  customer_navigation_esg_logout: "Ausloggen",
  customer_navigation_esg_esg: "ESG-Tool",
  customer_navigation_esg_lksg: "LkSG-Tool",
  customer_navigation_esg_csrd: "CSRD-Tool",
  customer_navigation_products: "Produkte",
  customer_navigation_other: "Sonstiges",
  customer_navigation_back_to_main_navigation: "Zurück zum Hauptmenü",
  customer_navigation_upload_section: "Upload Bereich",
  customer_navigation_navigation_surveys: "SURVEYS",
  customer_navigation_configuration: "Konfiguration",

  // System admin navigation items
  system_admin_navigation_co2_database: "CO<sub>2</sub> Datenbank",
  system_admin_navigation_prices: "CO<sub>2</sub> Type Prices",
  system_admin_navigation_sco2pes: "GLOBAL FOOTPRINT",
  system_admin_navigation_compliance: "GLOBAL COMPLIANCE",
  system_admin_navigation_surveys: "Surveys",
  system_admin_navigation_survey_templates: "Survey Templates",
  system_admin_navigation_risk_analyses: "ESG Risiko Analysen",
  system_admin_navigation_support: "Support",
  system_admin_navigation_users_and_companies: "Users & Companies",
  system_admin_navigation_users: "Users",
  system_admin_navigation_companies: "Unternehmen",
  system_admin_navigation_company_groups: "Unternehmensgruppen",
  system_admin_navigation_units: "Einheiten",
  system_admin_navigation_distance_calc: "Entfernungsberechnung",
};
