export enum Role {
  COMPANY_USER = "COMPANY_USER",
  CCF_USER = "CCF_USER",
  COMPLIANCE_USER = "COMPLIANCE_USER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
}

export enum ProfileState {
  PROFILE_CREATED = "PROFILE_CREATED",
  PROFILE_COMPLETED = "PROFILE_COMPLETED",
  PROFILE_DEACTIVATED = "PROFILE_DEACTIVATED",
  INVITATION_EXPIRED = "INVITATION_EXPIRED",
}
