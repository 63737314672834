/* eslint-disable */
export const messages = {
  // Header and footer
  common_global_climate_platform: "GLOBAL SUITE",
  common_imprint: "Imprint",
  common_terms_of_services: "Term & Conditions",
  common_data_security: "Data Security",

  // Buttons
  common_button_close: "Close",
  common_button_yes: "Yes",
  common_button_continue: "Continue",
  common_button_no: "No",
  common_button_ok: "OK",
  common_button_back: "Back",
  common_button_submit: "Submit",
  common_button_cancel: "Cancel",
  common_button_download: "Download",
  common_button_upload: "Upload",
  common_button_create: "Create",
  common_button_finalise: "Finalise",
  common_button_save: "Save",
  common_button_add: "Add",
  common_button_start: "Start",
  common_button_send: "Send",
  common_button_next: "Next",
  common_button_import: "Import",
  common_button_more: "Show more",
  common_button_less: "Show less",
  common_button_select: "Select",
  common_button_unselect: "Unselect",
  common_button_lock: "Lock",
  common_button_see_details: "See details",
  common_button_not_now: "Not now",
  common_button_details: "Details",
  common_button_finish: "Finish",
  common_button_clone: "Clone",
  common_button_export_as_excel: "Export as Excel",
  common_button_compare_emission: "Compare Emission",
  common_button_generate: "Generate",
  common_button_deactivate_selected_co2_types: "Deactivate selected CO<sub>2</sub> Types",
  common_button_activate_selected_co2_types: "Activate selected CO<sub>2</sub> Types",
  common_button_copy_error_message: "Copy Error Message",
  common_button_open_in_new_tab: "Open in new tab",

  // Distance Calculator
  common_distance_calculator_origin: "Origin",
  common_distance_calculator_destination: "Destination",
  common_distance_road: "Road",
  common_distance_rail: "Rail",
  common_distance_sea: "Sea",
  common_distance_air: "Air",

  // Inputs / tables
  common_general_no_data: "No data found",
  common_general_type_for_search: "Start typing for search",
  common_general_email: "Email",
  common_general_password: "Password",
  common_general_company: "Company",
  common_general_editor: "Editor",
  common_general_date: "Date",
  common_general_state: "State",
  common_general_failed_login_attempts: "Failed logins",
  common_general_number_of_partners: "Invited Partners",
  common_general_quantity: "Quantity",
  common_general_status: "Status",
  common_general_category: "Category",
  common_general_currency: "Currency",
  common_general_percentage: "Percentage",
  common_general_account_number: "Account Number",
  common_general_internal_order_number: "Internal Order Number",
  common_general_name: "Name",
  common_general_names: "Names",
  common_general_code: "Code",
  common_general_model: "Model",
  common_general_first_name: "First name",
  common_general_last_name: "Last name",
  common_general_creator: "Creator",
  common_general_creation_date: "Creation date",
  common_general_comment: "Comment",
  common_general_address: "Address",
  common_general_profile: "Profile",
  common_general_mail_language: "Mail Language",
  common_general_all: "All",
  common_general_loading_data: "Loading data...",
  common_general_no_data_available: "No data available",
  common_general_not_set: "Not Set",
  common_general_company_code: "Company Code",
  common_general_company_name: "Company Name",
  common_general_company_codes: "Company Codes",
  common_general_order_period: "Order Period",
  common_general_period: "Period",
  common_general_scope: "Scope",
  common_general_from: "From",
  common_general_until: "Until",
  common_general_versions_count: "Versions Count",
  common_general_started: "Started",
  common_general_deadline_at: "Deadline At",
  common_general_evaluation_period: "Evaluation Period",
  common_general_total: "Total",
  common_general_general: "General",
  common_general_environment: "Environment",
  common_general_social: "Social",
  common_general_governance: "Governance",
  common_general_year: "Year",
  common_general_validity: "Validity",
  common_general_valid_from: "Valid From",
  common_general_valid_until: "Valid Until",
  common_general_scorecard: "Scorecard",
  common_general_requested_at: "Requested At",
  common_general_requested_by: "Requested By",
  common_general_select_type: "Select CO<sub>2</sub> Type",
  common_general_selected_type: "Selected CO<sub>2</sub> Type",
  common_general_select: "Select",
  common_general_created_at: "Created At",
  common_general_type: "Type",
  common_general_created_by: "Created By",
  common_general_financial_models: "Financial Models",
  common_general_supplier: "Supplier",
  common_general_suppliers: "Suppliers",
  common_general_materials: "Materials",
  common_general_amount: "Amount",
  common_general_row: "Row",
  common_general_template: "Template",
  common_general_icon: "Icon",
  common_general_accounts: "Accounts",
  common_general_valid: "Valid",
  common_general_invalid: "Invalid",
  common_general_item_selected: "Item selected",
  common_general_items_selected: "Items selected",
  common_general_companies_selected: "Companies selected",
  common_general_upload_file: "Upload file",
  common_general_upload_file_or_drag_drop_file: "File (click or drag & drop)",
  common_general_set_starting_version_date: "Set version start date",
  common_file_upload_drop_file_here: "Drop file here",
  common_general_filter_results_by_name: "Filter results by name",
  common_general_lock_version: "Lock version",
  common_general_lock_version_recommendation:
    "Locking a version is recommended after the creation of a report to ensure that calculated emissions do not change.",
  common_general_lock_version_question: "Do you really want to lock the version?",
  common_general_lock_version_warning:
    "Once a version is locked it is <b>not possible to modify the data of that version</b> as well as the <b>starting from month of the follow up version</b>.",
  common_general_locked_version_description:
    "Locking a version is only possible if there is a follow up version existing and if the data of the version which should get locked is complete.",
  common_general_version_adding_description:
    "When adding a new version, the data from the last version will be copied. The data of a version can be modified after adding the new version.",
  common_general_information: "Information",
  common_general_title: "Title",
  common_general_description: "Description",
  common_general_value: "Value",
  common_general_deadline: "Deadline",
  common_general_yes: "Yes",
  common_general_no: "No",
  common_general_or: "Or",
  common_general_drag_drop: "Drag and drop your .xlsx file",
  common_general_choose_file: "Choose file",
  common_general_file_selected: "File selected",
  common_general_row_success_import: "{0} row(s) successfully imported",
  common_general_checks: "Checks",
  common_number_of_employees: "Number of employees",
  common_general_managed: "Managed",
  common_general_co2_type: "CO<sub>2</sub> Type",
  common_general_financial_model: "Financial Model",
  common_general_geographies: "Geographies",
  common_general_documentation: "Documentation",
  common_general_reset: "Reset",
  common_general_disable: "Disable",
  common_general_co2_category_deactivated: "CO<sub>2</sub> Category is deactivated",
  common_general_click_to_copy_id: "Click to copy ID",
  common_general_id_copied: "ID copied!",
  common_general_co2_type_tag: "CO<sub>2</sub> Type Tag",
  common_general_tag: "Tag",
  common_general_click_to_see: "Click to see details",
  common_general_you: "You",
  common_general_view_more: "View more",
  common_general_error_message: "Error Message",
  common_general_row_number: "Row Number",
  common_general_message: "Message",
  common_general_file_processed: "File has been processed",
  common_general_download_results: "Download results",
  common_general_results_not_downloaded: "Results not downloaded",
  common_general_results_not_downloaded_message:
    "Results were not downloaded. Do you really want to continue without downloading the upload results?",
  common_general_show_active: "Show active",
  common_general_problems: "Problems",
  common_general_issues: "Issues",
  common_general_overview: "Overview",
  common_general_external_ids: "External IDs",
  common_general_only_active_co2_types: "Only active CO<sub>2</sub> Types",
  common_general_sender_info: "Sender information",
  common_general_sender: "Sender",
  common_general_recipient: "Recipient",
  common_general_started_at: "Started at",

  // Not found
  common_general_errors: "Error(s)",
  common_not_found_text: "Not Found",
  common_not_found_button: "Go to the platform",
  common_not_in_use: "unused",
  common_upload_size_error: "File is too big. Please select a file smaller than 5 Megabytes.",

  // Actions
  common_general_actions: "Actions",
  common_general_search: "Search",
  common_general_free_text_search: "Free Text Search",
  common_general_edit: "Edit",
  common_general_exit: "Sign Out",

  // Address
  common_industry: "Industry",
  common_street: "Street",
  common_house_number: "House Number",
  common_postal_code: "Postal Code",
  common_city: "City",
  common_country: "Country",
  common_origin: "Origin",
  common_destination: "Destination",
  common_transit: "Stop/Transit",
  common_geography: "Geography",

  // Already logged in dialog
  common_already_logged_in: "You are already logged in.",
  common_general_to_platform: "Go to Platform",

  // Delete swal dialog
  common_duplicate_dialog_title: "Duplicate",
  common_duplicate_dialog_description: "Do you really want to duplicate the element?",
  common_duplicate_dialog_confirm_button: "Yes, duplicate!",

  // Delete swal dialog
  common_delete_dialog_title: "Deletion",
  common_delete_dialog_description: "Do you really want to delete the element?",
  common_delete_dialog_confirm_button: "Delete",

  // Units
  common_units: "Units",
  common_units_unit: "Unit",
  common_units_kg: "kg",

  // Input rules
  common_input_rule_required: "Required",
  common_input_rule_file_required: "You must upload the file",
  common_input_rule_number_must_be_number: "Input must be a number",
  common_input_rule_number_must_be_positive: "Input must be positive",
  common_input_rule_number_must_not_be_negative: "Input must not be negative",
  common_input_rule_number_greater_than_equal_and_less_than_equal: "Input must be between {0} and {1}",
  common_input_rule_number_greater_than_and_less_than_equal: "Input must be greater {0} and less than or equal to {1}",
  common_input_rule_space_not_allowed: "Space not allowed",
  common_input_rule_exceeds_maximum_length: "Exceeds maximum length of {0} characters",
  common_input_rule_bellow_minimum_length: "Must be at least {0} characters long",
  common_input_rule_length_between: "Must be at between {0} and {1} characters long",
  common_input_rule_at_least_one_letter: "Must have at least one letter",
  common_input_rule_at_least_one_number: "Must have at least one number",
  common_input_rule_at_least_one_special_character:
    "Must have at least one special character (!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~ )",
  common_input_rule_email_must_match_pattern: "Must be an email",
  common_input_rule_order_period_must_match_pattern: "Must be of format: YYYY-MM",
  common_input_rule_order_period_invalid_year: "The year must be greater than 2000",
  common_input_rule_order_period_invalid_month: "The month must be between 01 and 12",
  common_input_rule_passwords_do_not_match: "Passwords don't match",
  common_input_rule_version_period_pattern: "Must be of pattern YYYY-MM",
  common_input_rule_must_satisfy_symbol_pattern: "Must satisfy symbol pattern",
  common_input_must_be_phone_number: "Must be phone number",

  // Common State
  common_enum_accepted: "Accepted",
  common_enum_active: "Active",
  common_enum_approved_by_customer: "Approved By Customer",
  common_enum_approved_by_system_admin: "Approved By System Admin",
  common_enum_closed: "Closed",
  common_enum_company_admin: "Company Admin",
  common_enum_company_user: "User",
  common_enum_completed: "Completed",
  common_enum_uncompleted: "Uncompleted",
  common_enum_relevant: "Relevant",
  common_enum_not_relevant: "Not Relevant",
  common_enum_connected: "Connected",
  common_enum_created: "Created",
  common_enum_activated: "Activated",
  common_enum_deactivated: "Deactivated",
  common_enum_expired: "Expired",
  common_enum_in_progress: "In Progress",
  common_enum_in_review: "In Review",
  common_enum_not_started: "Not Started",
  common_enum_open: "Open",
  common_enum_ordered: "Ordered",
  common_enum_paid: "Paid",
  common_enum_pending: "Pending",
  common_enum_released: "Released",
  common_enum_ccf_user: "GLOBAL FOOTPRINT User",
  common_enum_compliance_user: "GLOBAL COMPLIANCE User",
  common_enum_system_admin: "System Admin",
  common_enum_uploaded: "Uploaded",
  common_enum_void: "Void",
  common_enum_waiting_for_customer: "Waiting for Customer",
  common_enum_waiting_for_support: "Waiting for Support",
  common_enum_cancelled: "Cancelled",
  common_enum_finalized: "Finalized",
  common_enum_success: "Success",
  common_enum_failed: "Failed",
  common_enum_ignored: "Ignored",
  common_enum_archived: "Archived",
  common_enum_upstream: "Upstream",
  common_enum_downstream: "Downstream",

  // File upload
  common_upload_in_progress: "Upload in progress... Please don't close this page!",
  common_file_not_processed: "File could not be processed",
  common_file_not_processed_description: "The file couldn't be processed. The following error(s) occurred",
  common_rows_with_errors_description: "The following row error(s) occurred",
  common_file_download_sample: "Download sample file",
  common_data_upload: "Data Upload",
  common_file_upload_only_single_file_supported: "Only a single file can be selected.",
  common_file_upload_wrong_file_type: "Wrong file type. Only Excel (.xlsx) supported.",
  common_file_upload_review_excel_data: "Please review the data extracted from the excel file",
  common_file_could_not_processed: "The file could not be processed. The following error(s) occurred",
  common_file_please_correct_the_file: "Please correct the Excel file before re-uploading it.",

  // Common texts
  common_please_wait: "Please Wait...",
  common_more: "more...",
  common_general_copied: "Copied",

  // Others
  common_component_type_not_supported: "Type {0} not supported.",
  common_unsaved_title: "Unsaved changes",
  common_unsaved_message: "There are unsaved changes, do your really want to close the dialog?",

  // Survey welcome
  common_survey_welcome_title: "You're invited to the survey!",
  common_survey_welcome_already_have_an_account: "Already have an account?",
  common_survey_welcome_survey_deadline: "The survey deadline is:",
  common_survey_welcome_invitation_code_used:
    "<b>The invitation code to survey was already used</b>, you can't register anymore with this link.",
  common_survey_welcome_go_to_login: "Go to login page",
  common_survey_welcome_content_info_1_esg:
    "<b>{partnerName}</b> is performing an <b>ESG Risk Analysis</b>. In the process of this <b>{partnerName}</b> asks you as a supplier to answer a survey.",
  common_survey_welcome_content_info_1_lksg:
    "<b>{partnerName}</b> is performing an <b>LkSG Risk Analysis</b> to fulfil its legal requirements. In the process of this <b>{partnerName}</b> asks you as a supplier to answer a survey.",
  common_survey_welcome_content_info_1_fallback: "<b>{partnerName}</b> asks all suppliers to answer a survey.",
  common_survey_welcome_content_info_2:
    "In order to be able to participate in the survey, you need to be registered or login on the GLOBAL SUITE.",
  common_survey_welcome_invitation_code_not_found: "Invitation code not found!",
  common_survey_welcome_check_invitation_code: "Check the invitation code and try again.",
  common_survey_welcome_registration_completed: "Registration successfully completed",
};
